<!--
 * @Author: wjc
 * @Date: 2023-12-08 14:54:42
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-11 11:35:27
 * @Description: 
-->
<template>
  <div class="flyout" :id="`NavScreenGroup-${menu.id}`">
    <div>
      <div
        class="flyout-button-text flex justify-between text-color-1 cursor-pointer"
        :class="[isOpen ? 'text-primary-color font-500' : '']"
        @click="toggle"
      >
        <span class="">{{ menu.name }}</span>
        <span
          :class="isOpen ? 'i-ep-arrow-up' : 'i-ep-arrow-down'"
          class="flyout-button-icon wh-14px ml-4px"
        ></span>
      </div>
      <div class="menu-group" :class="[isOpen ? 'open' : '']">
        <div v-for="item in menu.children" :key="item.id" class="py-6px">
          <MLink :to="item.to || `/content/${item.id}`" @click="closeScreen">
            {{ item.name }}
          </MLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { IInfoLevel } from '~/models/activity'

  defineOptions({
    name: 'MenuGroup',
  })

  const props = defineProps<{
    menu: IInfoLevel
  }>()

  const isOpen = ref(false)

  const closeScreen = inject('close-screen') as () => void

  const toggle = () => {
    isOpen.value = !isOpen.value
  }
</script>

<style scoped lang="scss">
  .flyout {
    @apply py-12px overflow-hidden;
    border-bottom: 1px solid var(--bg-divider);
    transition: border-color 0.5s;

    :deep(.nline-block) {
      @apply block w-full;
    }

    .menu-group {
      @apply invisible overflow-hidden h-0 ml-10px;

      :deep(.nuxt-link) {
        @apply block w-full my-10px;
      }
    }

    .menu-group.open {
      @apply py-10px visible h-auto;
    }
  }
</style>
